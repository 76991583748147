export default class HeaderSearchBar {
  constructor(el) {
    this.el = el
    this.openBtn = this.el.querySelector('.open-btn')
    this.searchForm = this.el.querySelector('.search-form')
    this.searchInput = this.el.querySelector('.search-form input')
    this.initEvents()
  }

  initEvents() {
    this.openBtn.addEventListener('click', e => {
      e.preventDefault()
      this.openSearch()
    })

    this.searchInput.addEventListener('blur', e => {
      if (this.searchInput.value.length === 0) {
        this.closeSearch()
      }
    })
  }

  openSearch() {
    this.openBtn.classList.add('hidden')
    this.searchForm.classList.remove('!hidden')
    this.searchInput.focus()
  }

  closeSearch() {
    this.openBtn.classList.remove('hidden')
    this.searchForm.classList.add('!hidden')
  }
}