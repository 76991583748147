import consumer from "./consumer"

//consumer.subscriptions.create({channel:"AppearanceChannel"}, {
//  initialized() {
//    console.log("Loading status.");
//  },
//
//  received(data) {
//    console.log(data);
//    var element = document.getElementById("user-status-" + String(data.user_id));
//    if (element) {
//      if (data.status) {
//        element.classList.add("online");
//      } else {
//        element.classList.remove("online");
//      }
//    }
//  },
//
//  connected() {
//  },
//
//  disconnected() {
//  },
//
//})
