export default class NavbarFixed {
  constructor (el) {
    this.el = el
    this.logoLink = this.el.querySelector('.header-logo-link')
    this.menuItems = this.el.querySelectorAll('.header-menu-item')
    // Get page-specific elements
    this.memberHeader = document.querySelector('.member-page-header')

    this.pageTopHideThreshold = 50

    window.addEventListener('scroll', () => {
      this.updateNavbarSize()
    })

    window.addEventListener('load', () => {
      this.updateNavbarSize()
    })
  }

  updateNavbarSize () {
    if (window.scrollY < this.pageTopHideThreshold) {
      this.expandNavbar()
    } else {
      this.shrinkNavbar()
    }
  }

  shrinkNavbar () {
    // Header height
    this.el.classList.add('scrolled')
    // Logo size
    this.logoLink.classList.add('lg:!w-36')
    // Link top padding
    this.menuItems.forEach(el => {
      el.classList.add('lg:!pt-3')
    })

    this.moveUpPageSpecificEls()

    document.body.classList.add('navbar-fixed-visible')
  }

  expandNavbar () {
    // Header height
    this.el.classList.remove('scrolled')
    // Logo size
    this.logoLink.classList.remove('lg:!w-36')
    // Link top padding
    this.menuItems.forEach(el => {
      el.classList.remove('lg:!pt-3')
    })

    this.moveDownPageSpecificEls()

    document.body.classList.remove('navbar-fixed-visible')
  }

  moveUpPageSpecificEls () {
    if (this.memberHeader) {
      this.memberHeader.classList.add('lg:!top-28')
    }
  }

  moveDownPageSpecificEls () {
    if (this.memberHeader) {
      this.memberHeader.classList.remove('lg:!top-28')
    }
  }
}
