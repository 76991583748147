export default class PhasesHeader {
  constructor (el) {
    this.el = el
    this.headerBg = this.el.querySelector('.phases-header-bg')
    this.pageHeader = document.querySelector('.phases-home-page-header')

    this.bgShowY = 30

    window.addEventListener('load', () => {
      this.updateBgShowY()
      this.updateBgVisibility()
    })

    window.addEventListener('scroll', () => {
      this.updateBgVisibility()
    })

    window.addEventListener('resize', () => {
      this.updateBgShowY()
    })
  }

  updateBgVisibility () {
    if (window.scrollY > this.bgShowY) {
      this.headerBg.classList.remove('opacity-0')
    } else {
      this.headerBg.classList.add('opacity-0')
    }
  }

  updateBgShowY () {
    if (this.pageHeader) {
      this.bgShowY = this.pageHeader.offsetHeight - this.el.offsetHeight
    }
  }
}