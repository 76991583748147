export default class PhasesMenu {
  constructor (el) {
    this.el = el
    this.menuToggle = document.querySelector('.menu-toggle-phases')

    if(this.menuToggle) {
      this.menuToggle.addEventListener('click', () => {
        if(this.el.classList.contains('is-open')) {
          this.closeMenu()
        } else {
          this.openMenu()
        }
      })
    }
  }

  openMenu () {
    this.el.classList.add('is-open')
    this.el.classList.remove('!hidden')
    this.menuToggle.classList.add('is-open')
    document.body.classList.add('overflow-hidden')
  }

  closeMenu () {
    this.el.classList.remove('is-open')
    this.el.classList.add('!hidden')
    this.menuToggle.classList.remove('is-open')
    document.body.classList.remove('overflow-hidden')
  }
}