export default class MemberPageHeader {
  constructor(el) {
    this.el = el
    this.innerEl = this.el.querySelector('.inner')
    this.headerHeight = this.innerEl.offsetHeight
    this.updateHeaderOpacity()

    window.addEventListener('load', () => {
      this.headerHeight = this.innerEl.offsetHeight
    })

    window.addEventListener('scroll', () => {
      this.updateHeaderOpacity()
    })
  }

  updateHeaderOpacity() {
    let opacity = 1 - this.getScrollPct()
    // Ensure opacity isn't negative
    opacity = (opacity < 0) ? 0 : opacity
    this.innerEl.style.opacity = opacity
  }

  getScrollPct() {
    return window.scrollY / this.headerHeight
  }
}