export default class MasonryGrid {
  constructor(el) {
    this.el = el
    this.gap = parseFloat(window.getComputedStyle(this.el).getPropertyValue('row-gap'))
    this.items = [...this.el.childNodes].filter(c => c.nodeType === 1)
    this.images = this.el.querySelectorAll('img')
    this.colCount = 0

    // Init layout after all images have loaded
    Promise.all(Array.from(this.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
      this.layoutItems()
    })

    window.addEventListener('resize', () => {
      this.layoutItems()
    })
  }

  layoutItems() {
    const colCount = window.getComputedStyle(this.el).getPropertyValue("grid-template-columns").split(" ").length

    if (this.colCount !== colCount) {
      // Update the number of columns
      this.colCount = colCount

      /* revert to initial positioning, no margin */
      this.items.forEach(c => c.style.removeProperty('margin-top'));

      /* If more than one column, update top margins */
      if(this.colCount > 1) {
        this.items.slice(colCount).forEach((c, i) => {
          let prevBottom = this.items[i].getBoundingClientRect().bottom, /* bottom edge of item above */
              curTop = c.getBoundingClientRect().top /* top edge of current item */

          c.style.marginTop = `${prevBottom + this.gap - curTop}px`
        })
      }
    }
  }
}