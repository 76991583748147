import consumer from "./consumer"

// TODO: fix jQuery error or convert to vanilla JS

if (typeof $ !== 'undefined') {
$(document).on('turbolinks:load', function () {
  console.log("Loading chat.");
   var el = $("#chat-box")
   var chatId      = el.attr("data-chat-id");
   var profileId   = el.attr("data-profile-id");
   var profileType = el.attr("data-profile-type");
   consumer.subscriptions.create({ channel: "ChatChannel",
                                   chat_id: chatId,
                                   profile_id: profileId,
                                   profile_type: profileType
     }, {
     received(data) {
       if (el.is(":visible")) {
         console.log("Loading message.");
         el.append(data);
         $.ajax({
           type: "GET",
           url: "/chats/"+chatId+"/view.json?profile_type="+profileType+"&profile_id="+profileId,
           dataType: 'json',
           success: function() {
             console.log("Adding message.");
           }
         });
         scrollChat();
       }
     }
   })
 });
}
