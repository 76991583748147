import ChatsNav from "../components/ChatsNav"
import Dropdown from "../components/Dropdown"
import MemberPageHeader from "../components/MemberPageHeader"
import MobileMenu from "../components/MobileMenu"
import ModalWindow from "../components/ModalWindow"
import NavbarFixed from "../components/NavbarFixed"
import PhasesHeader from "../components/PhasesHeader"
import PhasesMenu from "../components/PhasesMenu"
import MasonryGrid from "../components/MasonryGrid"
import { setUsableVwCssProp } from "../util/RSUtils"
import HeaderSearchBar from "../components/HeaderSearchBar"

export default {
  init () {
    document.addEventListener('turbolinks:load', () => {
      if (document.querySelector('.mobile-menu')) {
        new MobileMenu(document.querySelector('.mobile-menu'))
      }

      // Masonry grid
      document.querySelectorAll('.grid-masonry').forEach(el => { new MasonryGrid(el) })

      // Fixed navbar
      document.querySelectorAll('.header-fixed').forEach(el => { new NavbarFixed(el) })

      // Chats nav
      document.querySelectorAll('.chats-nav-outer').forEach(el => { new ChatsNav(el, '.chats-nav-back') })

      // Modal windows
      document.querySelectorAll('.rs-modal-trigger').forEach(el => { new ModalWindow(el, el.dataset.target) })

      // Global search
      document.querySelectorAll('.header-search-bar').forEach(el => { new HeaderSearchBar(el) })

      // Phases header
      if (document.querySelector('.phases-header')) {
        new PhasesHeader(document.querySelector('.phases-header'))
      }

      // Phases menu
      if (document.querySelector('.phases-menu')) {        
        new PhasesMenu(document.querySelector('.phases-menu'))
      }

      // Member page heeader
      if (document.querySelector('.member-page-header')) {
        new MemberPageHeader(document.querySelector('.member-page-header'))
      }

      // Dropdowns
      // If jQuery / legacy JS is removed, uncomment line below
      // document.querySelectorAll('.rs-dropdown').forEach(el => { new Dropdown(el) })

      setUsableVwCssProp()
      window.addEventListener('resize', setUsableVwCssProp)
    })
  }
}
