export default class ChatsNav {
  constructor (el, backSelector) {
    this.el = el
    this.chatItemLinks = this.el.querySelectorAll('.chat-item a.context-toggle')
    this.backBtn = document.querySelector(backSelector)

    this.chatItemLinks.forEach(el => {
      el.addEventListener('click', event => {
        event.preventDefault()
        this.closeNav()
      })
    })

    this.backBtn.addEventListener('click', () => {
      this.openNav()
    })
  }

  openNav() {
    this.el.classList.remove('hidden')
  }

  closeNav() {
    this.el.classList.add('hidden')
  }
}
