export default class MobileMenu {
  constructor (el) {
    this.el = el
    this.menuToggle = document.querySelector('.mobile-menu-toggle')
    this.menuToggleHamburger = document.querySelector('.menu-toggle-icon-hamburger')
    this.menuToggleClose = document.querySelector('.menu-toggle-icon-close')
    this.menuItems = this.el.querySelectorAll('.mobile-menu-item')

    this.menuToggle.addEventListener('click', () => {
      if(this.el.classList.contains('is-open')) {
        this.closeMenu()
      } else {
        this.openMenu()
      }
    })

    this.menuItems.forEach((el, index) => {
      let submenu = el.querySelector('.submenu')
      let submenuToggle = el.querySelector('.mobile-submenu-toggle')

      if (submenuToggle) {
        submenuToggle.addEventListener('click', event => {
          if(submenu && submenu.classList.contains('is-open')) {
            this.closeSubmenu(index)
          } else {
            this.openSubmenu(index)
          }
        })
      }
    });
  }

  openMenu () {
    this.el.classList.add('is-open')
    this.el.classList.remove('hidden')
    this.menuToggleHamburger.classList.add('hidden')
    this.menuToggleClose.classList.remove('hidden')
    document.body.classList.add('overflow-hidden')
  }

  closeMenu () {
    this.el.classList.remove('is-open')
    this.el.classList.add('hidden')
    this.menuToggleHamburger.classList.remove('hidden')
    this.menuToggleClose.classList.add('hidden')
    document.body.classList.remove('overflow-hidden')
  }

  openSubmenu(index) {
    let submenu = this.menuItems[index].querySelector('.submenu')
    let chevron = this.menuItems[index].querySelector('.chevron')

    // Close any other submenus that are open
    this.menuItems.forEach((el, j) => {
      if(j !== index) {
        this.closeSubmenu(j)
      }
    })
    
    // Open the target submenu
    submenu.classList.remove('hidden')
    submenu.classList.add('is-open')
    chevron.classList.add('rotate-180')
  }

  closeSubmenu(index) {
    console.log(this.menuItems[index]);
    let submenu = this.menuItems[index].querySelector('.submenu')
    let chevron = this.menuItems[index].querySelector('.chevron')
    if (submenu !== null) {
      submenu.classList.add('hidden')
      submenu.classList.remove('is-open')
      if (chevron) {
        chevron.classList.remove('rotate-180')
      }
    }
  }
}
